import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { CardDeck } from "react-bootstrap";

//import configs
// import { serviceConfigs } from "../configs/Configs";

// import redux
import * as workoutActions from "../store/modules/workout";
import * as errorActions from "../store/modules/error";

// import components
import AlertMessage from "../components/AlertMessage";
import NotificationPanel from "../components/NotificationPanel";
import BoardCard from "../components/BoardCard";
import VideoPost from "../components/VideoPost";

export class WorkoutPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "list",
      postIndex: null,
    };

    this.setContent = this.setContent.bind(this);
    this.handleClickBtn = this.handleClickBtn.bind(this);
  }

  componentDidMount() {
    this.props.loadPostList();
  }

  setContent(type, index = null) {
    switch (type) {
      case "list":
        if (Array.isArray(this.props.postList)) {
          return (
            <CardDeck>
              {this.props.postList.map((p, i) => {
                return (
                  <BoardCard
                    key={i}
                    index={i}
                    {...p}
                    handleClickBtn={this.handleClickBtn}
                  />
                );
              })}
            </CardDeck>
          );
        } else {
          return null;
        }
      case "article":
        return (
          <VideoPost
            {...this.props.postList[index]}
            handleClickBtn={this.handleClickBtn}
          />
        );
      default:
        return null;
    }
  }

  handleClickBtn(i = 0) {
    switch (this.state.type) {
      case "list":
        return this.setState({
          type: "article",
          postIndex: i,
        });
      case "article":
        return this.setState({
          type: "list",
          postIndex: null,
        });
    }
  }

  render() {
    let alert,
      content = null;

    content = this.setContent(this.state.type, this.state.postIndex);

    if (this.props.error) {
      alert = <AlertMessage error={this.props.errDetail} />;
    }

    return (
      <div className="c2_container">
        {alert}
        <NotificationPanel title="홈트레이닝"></NotificationPanel>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.error.get("error"),
  errDetail: state.error.get("errDetail").toJS(),
  postList: state.workout.getIn(["postData", "list"]),
});

const mapDispatchToProps = (dispatch) => ({
  resetError: () => dispatch(errorActions.resetError()),
  loadPostList: () => dispatch(workoutActions.loadPostList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutPage);
