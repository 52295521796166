import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

//import configs
import { serviceConfigs } from '../configs/Configs';

import NotificationPanel from '../components/NotificationPanel';
import NumberCard from '../components/NumberCard';
import BookedSchedule from '../components/BookedSchedule';
import AlertMessage from '../components/AlertMessage';

// import redux
import * as bookedActions from '../store/modules/booked';
import * as errorActions from '../store/modules/error';

export class BookedPage extends Component {
  constructor(props) {
    super(props);

    const branchId = localStorage.getItem('branchId');
    const start = moment().utc();
    const end = moment()
      .add(serviceConfigs.groupBookingLimit[branchId - 1], 'w')
      .endOf('day')
      .utc();
    this.props.loadBookedSchedule(start, end);
  }

  refreshBookedSchedule() {
    const branchId = localStorage.getItem('branchId');
    const start = moment().utc();
    const end = moment()
      .add(serviceConfigs.groupBookingLimit[branchId - 1], 'w')
      .endOf('day')
      .utc();
    this.props.loadBookedSchedule(start, end);
  }

  calScheduleCount(list) {
    let groupSchedules = list.filter((s) => {
      return (
        serviceConfigs.groupClassType.indexOf(
          serviceConfigs.classType[s.type - 1]
        ) >= 0
      );
    });
    let bookedGroupSchedules = groupSchedules.filter((s) => {
      return s.member[0].memberSchedule.status === false;
    });
    let watiningGroupSchedules = groupSchedules.filter((s) => {
      return s.member[0].memberSchedule.status === 2;
    });
    let bookedPTSchedules = list.filter((s) => {
      return (
        serviceConfigs.ptClassType.indexOf(
          serviceConfigs.classType[s.type - 1]
        ) >= 0
      );
    });
    return {
      bookedGroup: bookedGroupSchedules.length,
      watiningGroup: watiningGroupSchedules.length,
      bookedPT: bookedPTSchedules.length,
    };
  }
  render() {
    const branchId = localStorage.getItem('branchId');
    let bookedGroup = 0,
      watiningGroup = 0,
      bookedPT = 0;
    let alert = null;
    let bookedSchedules = null;

    if (this.props.error) {
      alert = <AlertMessage error={this.props.errDetail} />;
    }

    if (this.props.cancelSuccesss) {
      window.alert(`취소되었습니다.`);
      this.refreshBookedSchedule();
    }

    if (this.props.bookedScheduleList) {
      const count = this.calScheduleCount(this.props.bookedScheduleList);
      bookedGroup = count.bookedGroup;
      watiningGroup = count.watiningGroup;
      bookedPT = count.bookedPT;

      bookedSchedules = this.props.bookedScheduleList.map((s, i) => {
        return (
          <BookedSchedule
            key={i}
            s={s}
            memberSchedule={s.member[0].memberSchedule}
            cancelBookedSchedule={this.props.cancelBookedSchedule}
          />
        );
      });
    }

    return (
      <div className="c2_container">
        {alert}
        <NotificationPanel title="예약 수업 관리">
          <p>
            <span className="text-danger">
              그룹수업 {serviceConfigs.groupBookingCancleLimit[branchId - 1]}
              시간 전까지 취소가 가능합니다. <br />
              취소하지 않은 예약은 수업 종료 후 세션이 차감되니 주의하시길
              바랍니다.
              <br />
              PT수업은 담당강사 혹은 센터로 문의하여야 합니다.
              <br />
            </span>
            {/* {serviceConfigs.usingWaiting? '대기 이력은 수업 종료 후 자동 삭제됩니다.':''} */}
            {serviceConfigs.usingWaiting[branchId - 1]
              ? '대기 이력은 수업 종료 후 자동 삭제됩니다.'
              : ''}
          </p>
        </NotificationPanel>
        <div className="card_list">
          <NumberCard title="그룹 예약" count={bookedGroup} />
          <NumberCard title="그룹 대기" count={watiningGroup} />
          <NumberCard title="PT 예약" count={bookedPT} />
        </div>
        {bookedSchedules}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.booked.get('loading'),
  cancelSuccesss: state.booked.get('cancelSuccess'),
  error: state.error.get('error'),
  errDetail: state.error.get('errDetail').toJS(),
  bookedScheduleList: state.booked.getIn(['scheduleData', 'list']),
});

const mapDispatchToProps = (dispatch) => ({
  loadBookedSchedule: (start, end) =>
    dispatch(bookedActions.loadBookedSchedule(start, end)),
  cancelBookedSchedule: (scheduleId) =>
    dispatch(bookedActions.cancelBookedSchedule(scheduleId)),
  resetError: () => dispatch(errorActions.resetError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookedPage);
