import React from 'react';
import moment from 'moment';
import { Badge, Button } from 'react-bootstrap';
import PTIcon from '../../lib/image/PT.png';
import GroupIcon from '../../lib/image/Group.png';

import { serviceConfigs } from '../../configs/Configs';

function setStatus(status) {
  let badgeStyle, attendingStatus;
  switch (status) {
    case false:
      badgeStyle = 'warning';
      attendingStatus = '미참석';
      break;
    case true:
      badgeStyle = 'info';
      attendingStatus = '참석';
      break;
    case 2:
      badgeStyle = 'warning';
      attendingStatus = '대기';
      break;
    default:
  }
  return {
    badgeStyle,
    attendingStatus,
  };
}

//  Need to work_20210402
// 사용자 장치의 시간대와 관계 없이 한국 시간이 나타나도록 수정 필요
function ScheduleCard(props) {
  const type = serviceConfigs.classType[props.s.type - 1];
  const date = `${moment(props.s.start).month() + 1}월 ${moment(
    props.s.start
  ).date()}일(${serviceConfigs.days[moment(props.s.start).day()]})`;
  const time = `${moment(props.s.start).format('HH:mm')}`;
  const status = setStatus(props.memberSchedule.status);
  const scheduleName = props.s.name;
  const scheduleType = serviceConfigs.classType[props.s.type - 1];
  const teacherName = props.s.teacher ? props.s.teacher.name : '강사 미정';
  const roomName = props.s.room ? props.s.room.name : '룸 미정';
  const feedback =
    props.s.feedback.length > 0 ? props.s.feedback[0].comment : null;
  let button = null;

  const handleAttedning = () => {
    props.attending(props.s.id);
  };

  if (props.memberSchedule.status === false && props.s.isFinished === true) {
    button = (
      <Button
        variant="outline-info"
        block
        onClick={handleAttedning}
      >{`출석`}</Button>
    );
  }

  return (
    <div className="BookedSchedule">
      <h6>
        <img
          src={
            serviceConfigs.groupClassType.indexOf(type) >= 0
              ? GroupIcon
              : PTIcon
          }
          className="schedule_icon"
          alt="logo"
        />
        &nbsp;&nbsp;
        {date}
      </h6>
      <h4>
        {`[${scheduleType}] ${time}`}{' '}
        <Badge variant={status.badgeStyle}>{status.attendingStatus}</Badge>
      </h4>
      <h6>{`${scheduleName}. ${teacherName}. ${roomName}`}</h6>
      {feedback && (
        <div className="Feedback">
          <h5>수업 피드백 : {feedback}</h5>
        </div>
      )}
      <div>{button}</div>
    </div>
  );
}

export default ScheduleCard;
