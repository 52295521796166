import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

//import configs
import { serviceConfigs } from '../configs/Configs';

//import components
import AlertMessage from '../components/AlertMessage';
import NotificationPanel from '../components/NotificationPanel';
import NumberCard from '../components/NumberCard';
import DateBox from '../components/DateBox';
import BookCatalogButton from '../components/BookCatalogButton';
import SessionListFirst from '../components/Session/SessionListFirst';

//import redux
import * as bookActions from '../store/modules/book';
import * as memberActions from '../store/modules/member';
import * as bookedActions from '../store/modules/booked';
import * as errorActions from '../store/modules/error';

export class BookPage extends Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    // set initialFilter
    this.props.changeFilter('time');
    // get scheduleList
    const branchId = localStorage.getItem('branchId');
    const start = moment().utc();
    const end = moment()
      .add(serviceConfigs.groupBookingLimit[branchId - 1], 'w')
      .endOf('day')
      .utc();
    this.props.loadGroupSchedules(start, end);
    this.props.loadBookedSchedule(start, end);
    this.props.loadMemberInfo();
    this.props.loadMemberSessionCnt();
  }

  refreshSchedule() {
    const branchId = localStorage.getItem('branchId');
    const start = moment().utc();
    const end = moment()
      .add(serviceConfigs.groupBookingLimit[branchId - 1], 'w')
      .endOf('day')
      .utc();
    this.props.loadGroupSchedules(start, end);
    this.props.loadBookedSchedule(start, end);
    this.props.loadMemberInfo();
    this.props.loadMemberSessionCnt();
  }

  render() {
    const branchId = localStorage.getItem('branchId');
    let alert = null;
    let canBookCnt = 0;
    let memberName = '회원';
    let expiredDate = '';

    if (this.props.error) {
      alert = <AlertMessage error={this.props.errDetail} />;
    }

    if (this.props.bookSuccess) {
      window.alert(`예약/대기가 완료되었습니다.`);
      this.refreshSchedule();
    }

    if (this.props.memberInfo && this.props.memberSessionCnt) {
      canBookCnt = this.props.memberInfo.sumOfGroupSession - this.props.memberSessionCnt.booked;
      memberName = this.props.memberInfo.name;
      expiredDate = moment(this.props.memberInfo.expiredDate).format('YYYY-MM-DD');
    }

    let notification = serviceConfigs.bookNotification.split('\n').map((line, i) => {
      return (
        <span key={i}>
          {line
            .replace('@memberName', memberName)
            .replace('@expiredDate', expiredDate)
            .replace('@groupBookingLimit', serviceConfigs.groupBookingLimit[branchId - 1])
            .replace('@groupBookingCancleLimit', serviceConfigs.groupBookingCancleLimit[branchId - 1])}
          <br />
        </span>
      );
    });
    let notificationDanger = serviceConfigs.bookNotificationDanger.split('\n').map((line, i) => {
      return (
        <span key={i} className="text-danger">
          {line
            .replace('@memberName', memberName)
            .replace('@expiredDate', expiredDate)
            .replace('@groupBookingLimit', serviceConfigs.groupBookingLimit[branchId - 1])
            .replace('@groupBookingCancleLimit', serviceConfigs.groupBookingCancleLimit[branchId - 1])}
          <br />
        </span>
      );
    });
    if (serviceConfigs.restrictBookSameTime[branchId - 1] === true) {
      notificationDanger.push(
        <span key={999} className="text-danger">
          {serviceConfigs.bookRestrictNotification}
        </span>
      );
    }

    return (
      <div className="c2_container">
        {alert}
        <NotificationPanel title="그룹 수업 예약 안내">
          <p>
            {notification}
            {notificationDanger}
          </p>
        </NotificationPanel>
        <div className="card_list">
          <NumberCard title="잔여 세션" count={this.props.memberInfo.sumOfGroupSession} />
          <NumberCard title="예약 완료" count={this.props.memberSessionCnt.booked} />
          <NumberCard title="예약 가능" count={canBookCnt} />
        </div>
        <BookCatalogButton handleClick={this.props.changeFilter} />
        <DateBox start={this.props.start} end={this.props.end} />
        <SessionListFirst
          scheduleList={this.props.scheduleList}
          bookedScheduleList={this.props.bookedScheduleList}
          filter={this.props.filter}
          bookGroupSchedule={this.props.bookGroupSchedule}
          expiredDate={this.props.memberInfo.expiredDate}
          canBookCnt={canBookCnt}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.book.get('loading'),
  bookSuccess: state.book.get('bookSuccess'),
  filter: state.book.get('filter'),
  start: state.book.getIn(['scheduleData', 'start']),
  end: state.book.getIn(['scheduleData', 'end']),
  error: state.error.get('error'),
  errDetail: state.error.get('errDetail').toJS(),
  scheduleList: state.book.getIn(['scheduleData', 'groupScheduleList']),
  memberInfo: state.member.get('memberData'),
  memberSessionCnt: state.member.getIn(['sessionData', 'cnt']),
  bookedScheduleList: state.booked.getIn(['scheduleData', 'list']),
});

const mapDispatchToProps = (dispatch) => ({
  changeFilter: (filter) => dispatch(bookActions.changeFilter(filter)),
  setStartDate: (date) => dispatch(bookActions.setStartDate(date)),
  setEndDate: (date) => dispatch(bookActions.setEndDate(date)),
  loadGroupSchedules: (start, end) => dispatch(bookActions.loadGroupSchedules(start, end)),
  bookGroupSchedule: (id) => dispatch(bookActions.bookGroupSchedule(id)),
  loadMemberInfo: () => dispatch(memberActions.loadMemberInfo()),
  loadMemberSessionCnt: () => dispatch(memberActions.loadSessionCnt()),
  loadBookedSchedule: (start, end) => dispatch(bookedActions.loadBookedSchedule(start, end)),
  resetError: () => dispatch(errorActions.resetError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookPage);
