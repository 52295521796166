import React from 'react';
import moment from 'moment';
import {
  InputGroup,
  FormControl
} from 'react-bootstrap';

function DateBox(props) {
  return (
    <div className="inputGroup">
      <InputGroup className="mb-3">
        <FormControl
          value={props.start? moment(props.start).format('YYYY-MM-DD'):''}
          type="date"
          disabled
        />&nbsp;~&nbsp;
        <FormControl
          value={props.end? moment(props.end).format('YYYY-MM-DD'):''}
          type="date"
          disabled
        />
      </InputGroup>
    </div>
  );
}

export default DateBox;