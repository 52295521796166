import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { InputGroup, FormControl } from "react-bootstrap";
import { FaUserAlt, FaMobile, FaCalendarCheck } from "react-icons/fa";

import NotificationPanel from "../components/NotificationPanel";
import NumberCard from "../components/NumberCard";

//import redux
import * as memberActions from "../store/modules/member";
import * as errorActions from "../store/modules/error";

export class InfoPage extends Component {
  constructor(props) {
    super(props);

    this.props.loadMemberTotalCnt();
    this.props.loadMemberInfo();
  }
  render() {
    let memberName = "회원",
      mobile = "",
      expiredDate = "";
    let ptSession = "",
      groupSession = "";
    let sessions = [];

    if (this.props.memberInfo) {
      memberName = this.props.memberInfo.name;
      mobile = this.props.memberInfo.mobile;
      expiredDate = moment(this.props.memberInfo.expiredDate).format(
        "YYYY-MM-DD"
      );
      ptSession = this.props.memberInfo.sumOfPTSession;
      groupSession = this.props.memberInfo.sumOfGroupSession;
    }
    return (
      <div className="c2_container">
        <NotificationPanel title="내 정보 관리">
          <p>
            안녕하세요. {memberName}님<br />
            정보가 변경된 경우, 담당 센터로 연락 부탁 드립니다.
          </p>
        </NotificationPanel>
        <SubTitle title="1. 회원 정보" />
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
              <FaUserAlt />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder={memberName}
            aria-label="이름"
            aria-describedby="basic-addon1"
            disabled
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
              <FaMobile />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder={mobile}
            aria-label="휴대전화 번호"
            aria-describedby="basic-addon1"
            disabled
          />
        </InputGroup>
        <SubTitle title="2. 세션 만료일" />
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
              <FaCalendarCheck />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder={expiredDate}
            aria-label="만료일"
            aria-describedby="basic-addon1"
            disabled
          />
        </InputGroup>
        {this.props.totalCnt
          ? Object.keys(this.props.totalCnt).map((key, i) => {
              return (
                <SessionCard
                  i={3 + i}
                  key={i}
                  name={key}
                  addSession={this.props.totalCnt[key].addSession}
                  useSession={this.props.totalCnt[key].useSession}
                />
              );
            })
          : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.book.get("loading"),
  error: state.error.get("error"),
  errDetail: state.error.get("errDetail").toJS(),
  memberInfo: state.member.get("memberData"),
  totalCnt: state.member.getIn(["sessionData", "totalCnt"]),
});

const mapDispatchToProps = (dispatch) => ({
  loadMemberInfo: () => dispatch(memberActions.loadMemberInfo()),
  loadMemberTotalCnt: () => dispatch(memberActions.loadMemberTotalSessionCnt()),
  resetError: () => dispatch(errorActions.resetError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoPage);

function SubTitle(props) {
  return (
    <div className="SubTitle">
      <h5>{props.title}</h5>
    </div>
  );
}

function SessionCard(props) {
  const addSession = props.addSession ? props.addSession : 0;
  const useSession = props.useSession ? props.useSession * -1 : 0;
  return (
    <div className="card_div">
      <SubTitle title={`${props.i}. ${props.name} 세션 정보`} />
      <div className="card_list">
        <NumberCard title="등록" count={addSession} />
        <NumberCard title="사용" count={useSession} />
        <NumberCard title="잔여" count={props.addSession + props.useSession} />
      </div>
    </div>
  );
}
