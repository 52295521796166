import React from 'react';
import { 
  ButtonToolbar, 
  Button,
} from 'react-bootstrap';

function BookCatalogButton(props) {
  const handleClick = (e) => {
    props.handleClick(e.target.id);
  }
  return (
    <div className="inputGroup">
      <ButtonToolbar>
        <Button variant="outline-secondary" id="time" onClick={handleClick}>시간</Button>
        <Button variant="outline-secondary" id="date" onClick={handleClick}>요일</Button>
        <Button variant="outline-secondary" id="teacher" onClick={handleClick}>강사</Button>
        <Button variant="outline-secondary" id="room" onClick={handleClick}>수업룸</Button>
        <Button variant="outline-secondary" id="name" onClick={handleClick}>수업명</Button>
      </ButtonToolbar>
      <ButtonToolbar>
        <Button variant="outline-secondary" id="type" onClick={handleClick}>수업종류</Button>
        <Button variant="outline-secondary" id="available" onClick={handleClick}>예약가능</Button>
      </ButtonToolbar>
    </div>
  );
}

export default BookCatalogButton;