import React, { Component } from 'react';
import { Card, Accordion } from 'react-bootstrap';
import { FaChevronDown, FaChevronLeft } from 'react-icons/fa';

import SessionListSecond from './SessionListSecond';
import SessionCard from './SessionCard';
import { bucketSort } from './SessionSort';

class SessionListCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }
  render() {
    const iconComponent = this.state.open ? <FaChevronDown /> : <FaChevronLeft />;

    const secondFilter = this.props.filter === 'time' ? 'date' : 'time';
    const sortedSchedules = bucketSort(this.props.schedules, secondFilter);
    const bucketKeys = Object.keys(sortedSchedules);
    return (
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey={this.props.index} onClick={() => this.setState({ open: !this.state.open })}>
          <ul className="SessionListFirst_list">
            <li>{this.props.title}</li>
            <li className="text_right">{iconComponent}</li>
          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={this.props.index}>
          <div>
            {bucketKeys.map((t, i) => {
              if (this.props.filter === 'available') {
                return (
                  <Card.Body>
                    <div className="card_list">
                      {sortedSchedules[t].map((s, i) => {
                        return (
                          <SessionCard
                            key={i}
                            index={i}
                            schedule={s}
                            bookedScheduleList={this.props.bookedScheduleList}
                            bookGroupSchedule={this.props.bookGroupSchedule}
                            expiredDate={this.props.expiredDate}
                            canBookCnt={this.props.canBookCnt}
                          />
                        );
                      })}
                    </div>
                  </Card.Body>
                );
              } else {
                return (
                  <SessionListSecond
                    key={i}
                    index={i + 1}
                    title={t}
                    schedules={sortedSchedules[t]}
                    bookedScheduleList={this.props.bookedScheduleList}
                    bookGroupSchedule={this.props.bookGroupSchedule}
                    expiredDate={this.props.expiredDate}
                    canBookCnt={this.props.canBookCnt}
                  />
                );
              }
            })}
          </div>
        </Accordion.Collapse>
      </Card>
    );
  }
}

export default SessionListCard;
