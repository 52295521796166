/*
 * src/store/modules/booked.js
 * BookedPage Container의 redux type, action, reducer
 */
import { fromJS } from 'immutable';

export const DEFAULT_ACTION = 'booked/DEFAULT_ACTION';
export const LOAD_BOOKED_SCHEDULE = 'booked/LOAD_BOOKED_SCHEDULE';
export const LOADED_BOOKED_SCHEDULE = 'booked/LOADED_BOOKED_SCHEDULE';
export const CANCEL_BOOKED_SCHEDULE = 'booked/CANCEL_BOOKED_SCHEDULE';
export const CANCELED_BOOKED_SCHEDULE = 'booked/CANCELED_BOOKED_SCHEDULE';

export const defaultAction = () => {
  return {
    type: DEFAULT_ACTION,
  };
}

export const loadBookedSchedule = (start, end) => {
  return {
    type: LOAD_BOOKED_SCHEDULE,
    start: start,
    end: end
  }
}
export const loadBookedScheduleSuccess = (res) => {
  return {
    type: LOADED_BOOKED_SCHEDULE,
    scheduleList: res
  }
}

export const cancelBookedSchedule = (scheduleId) => {
  return {
    type: CANCEL_BOOKED_SCHEDULE,
    scheduleId: scheduleId
  }
}
export const cancelBookedScheduleSuccess = () => {
  return {
    type: CANCELED_BOOKED_SCHEDULE
  }
}

const initialState = fromJS({
  loading: false,
  cancelSuccess: false,
  filter: false,
  scheduleData: fromJS({
    list: false,
    start: false,
    end: false,
  }),
});

export default function bookedReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case LOAD_BOOKED_SCHEDULE:
      return state
        .set('loading', false)
        .set('cancelSuccess', false)
        .setIn(['scheduleData', 'start'], action.start? action.start:state.getIn(['scheduleData', 'start']))
        .setIn(['scheduleData', 'end'], action.end? action.end:state.getIn(['scheduleData', 'end']))
        .setIn(['scheduleData', 'list'], false);
    case LOADED_BOOKED_SCHEDULE:
      return state
        .set('loading', true)
        .setIn(['scheduleData', 'list'], action.scheduleList);
    case CANCEL_BOOKED_SCHEDULE:
      return state
        .set('cancelSuccess', false);
    case CANCELED_BOOKED_SCHEDULE:
      return state
        .set('cancelSuccess', true);
    default:
      return state;
  }
}