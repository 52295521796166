import React from "react";
import moment from "moment";
import { Card, Badge, Button } from "react-bootstrap";

import { serviceConfigs } from "../../configs/Configs";
// const serviceConfigs = window.ENV.serviceConfigs;

function VideoPost(props) {
  let sumnail,
    videoSrc,
    title,
    content = "";

  title = props.title;
  content = props.content.split("\\n").map((s, i) => {
    return (
      <span key={i}>
        {s}
        <br />
      </span>
    );
  });

  props.attachments.map((a, i) => {
    switch (a.type) {
      case 1:
        sumnail = a.url;
        break;
      case 2:
        videoSrc = a.url;
        break;
      default:
        break;
    }
  });

  const onClickBtn = () => {
    props.handleClickBtn();
  };

  return (
    <div className="VideoPost">
      <h4>{title}</h4>
      <p>{content}</p>
      <video controls poster={sumnail}>
        <source src={videoSrc} />
      </video>
      <Button variant="primary" onClick={onClickBtn}>
        목록으로 돌아가기
      </Button>
    </div>
  );
}

export default VideoPost;
