import React from "react";

import { serviceConfigs } from "../../configs/Configs";

function Footer() {
  return (
    <footer className="Footer">
      <div className="c3_container">
        <div className="f_c">
          <p>{`${serviceConfigs.serviceName}`}</p>
        </div>
        <div className="f_c">
          <p>
            Service CoC By
            <br />
            펭귄파트너스
            <br />
            508-12-16222
            <br />
            penguin_partners@naver.com
          </p>
        </div>
        <div className="copyright">
          ⓒ 2020. 펭귄파트너스
          <br />
          all rights reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
