import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

//import configs
import { serviceConfigs } from '../configs/Configs';

// import redux
import * as bookedActions from '../store/modules/booked';
import * as sessionsActions from '../store/modules/sessions';
import * as errorActions from '../store/modules/error';

// import components
import NotificationPanel from '../components/NotificationPanel';
import NumberCard from '../components/NumberCard';
import DateBox from '../components/DateBox';
import DateButton from '../components/DateButton';
import ScheduleCard from '../components/ScheduleCard';

export class SessionsPage extends Component {
  constructor(props) {
    super(props);

    const start = moment().startOf('month');
    const end = moment().endOf('month');
    this.props.setTargetDate(start, end);
    this.props.loadBookedSchedule(start, end);

    this.changeDate = this.changeDate.bind(this);
  }

  refreshSessions() {
    const start = this.props.start;
    const end = this.props.end;
    this.props.setTargetDate(start, end);
    this.props.loadBookedSchedule(start, end);
  }

  changeDate(type) {
    let start = this.props.start;
    let end = this.props.end;
    switch (type) {
      case '1':
        start = moment(start).subtract(1, 'month').startOf('month');
        end = moment(end).subtract(1, 'month').endOf('month');
        break;
      case '2':
        start = moment().startOf('month');
        end = moment().endOf('month');
        break;
      case '3':
        start = moment(start).add(1, 'month').startOf('month');
        end = moment(end).add(1, 'month').endOf('month');
        break;
      default:
    }
    this.props.setTargetDate(start, end);
    this.props.loadBookedSchedule(start, end);
  }

  calScheduleCount(list) {
    let groupSchedules = list.filter((s) => {
      return (
        serviceConfigs.groupClassType.indexOf(
          serviceConfigs.classType[s.type - 1]
        ) >= 0
      );
    });
    let PTSchedules = list.filter((s) => {
      return (
        serviceConfigs.ptClassType.indexOf(
          serviceConfigs.classType[s.type - 1]
        ) >= 0
      );
    });
    return {
      total: list.length,
      group: groupSchedules.length,
      pt: PTSchedules.length,
    };
  }

  render() {
    let total = 0,
      group = 0,
      pt = 0;
    let sessions = null;

    if (this.props.updateSuccess) {
      this.refreshSessions();
    }

    if (this.props.scheduleList) {
      const count = this.calScheduleCount(this.props.scheduleList);
      total = count.total;
      group = count.group;
      pt = count.pt;

      sessions = this.props.scheduleList.map((s, i) => {
        return (
          <ScheduleCard
            key={i}
            s={s}
            memberSchedule={s.member[0].memberSchedule}
            attending={this.props.updateAttending}
          />
        );
      });
    }

    return (
      <div className="c2_container">
        <NotificationPanel title="수업 이력">
          <p>
            수업 이력에는 예약된 수업과 완료된 수업이 모두 표시됩니다. <br />
            완료된 수업은 출석 버튼을 클릭하여, 출석 체크를 하실 수 있습니다.{' '}
            <br />
          </p>
        </NotificationPanel>
        <DateButton changeDate={this.changeDate} />
        <DateBox start={this.props.start} end={this.props.end} />
        <div className="card_list">
          <NumberCard title="총 수업" count={total} />
          <NumberCard title="PT 수업" count={pt} />
          <NumberCard title="그룹 수업" count={group} />
        </div>
        {sessions}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.error.get('error'),
  errDetail: state.error.get('errDetail').toJS(),
  updateSuccess: state.sessions.get('updateSuccess'),
  start: state.sessions.getIn(['sessionData', 'start']),
  end: state.sessions.getIn(['sessionData', 'end']),
  scheduleList: state.booked.getIn(['scheduleData', 'list']),
});

const mapDispatchToProps = (dispatch) => ({
  resetError: () => dispatch(errorActions.resetError()),
  setTargetDate: (start, end) =>
    dispatch(sessionsActions.setTartgetDate(start, end)),
  loadBookedSchedule: (start, end) =>
    dispatch(bookedActions.loadBookedSchedule(start, end)),
  updateAttending: (scheduleId) =>
    dispatch(sessionsActions.updateAttending(scheduleId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionsPage);
