import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function NotificationModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>수업 안내</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        안녕하세요 문래 드림필라테스 입니다
        <br />
        <br />
        5월 4일 토요일부터 5월 6일 월요일까지 센터 휴무임을 안내드립니다
        <br />
        <br />
        수업 예약 시 참고하시길 바랍니다.
      </Modal.Body>

      {/* <Modal.Body>
        <div className="NotificationModal">
          <img src="https://storage.googleapis.com/storage.cocore.co.kr/images/Dream_noti_20240111.jpeg" />
        </div>
      </Modal.Body> */}

      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NotificationModal;
