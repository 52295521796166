/*
 * redux-saga index.js
 * request to api and return the answer
 * basically, every request will retrun response.body.data from util/request
 */
import { call, put, takeLatest } from "redux-saga/effects";
import jwt_decode from "jwt-decode";

import request from "../../lib/utils/request";
import { apiConfigs } from "../../configs/Configs";

import { setError } from "../modules/error";
import { GET_AUTH, requestSuccess } from "../modules/login";
import {
  LOAD_GROUP_SCHEDULES,
  BOOK_GROUP_SCHEDULE,
  loadGroupSchedulesSuccess,
  bookGroupScheduleSuccess,
} from "../modules/book";
import {
  LOAD_BOOKED_SCHEDULE,
  loadBookedScheduleSuccess,
  CANCEL_BOOKED_SCHEDULE,
  cancelBookedScheduleSuccess,
} from "../modules/booked";
import {
  LOAD_MEBMER_INFO,
  loadMemberInfoSuccess,
  LOAD_SESSION_CNT,
  loadSessionCntSuccess,
  LOAD_MEMBER_TOTAL_SESSION_CNT,
  loadMemberTotalSessionCntSuccess,
} from "../modules/member";
import { UPDATE_ATTENDING, updateAttendingSuccess } from "../modules/sessions";
import { LOAD_POST_LIST, loadPostListSuccess } from "../modules/workout";

// Login request
function* getAuth(action) {
  try {
    const res = yield call(request, apiConfigs.authURL, {
      method: "POST",
      data: JSON.stringify(action.user),
    });
    localStorage.setItem("access-token", res.data["access-token"]);
    let decoded = jwt_decode(res.data["access-token"]);
    localStorage.setItem("branchId", decoded.branchId);
    yield put(requestSuccess());
  } catch (error) {
    yield put(setError(error));
  }
}

function* getGroupScheduleList(action) {
  try {
    const res = yield call(
      request,
      `${apiConfigs.userGroupSchedulesURL}?start=${action.start}&end=${action.end}`,
      {
        method: "GET",
      }
    );
    yield put(loadGroupSchedulesSuccess(res.data));
  } catch (error) {
    yield put(setError(error));
  }
}

function* getMemberInfo() {
  try {
    const res = yield call(request, `${apiConfigs.userURL}`, {
      method: "GET",
    });
    yield put(loadMemberInfoSuccess(res.data));
  } catch (error) {
    yield put(setError(error));
  }
}

function* getMemberSessionCnt() {
  try {
    const res = yield call(
      request,
      `${apiConfigs.userCountBookedSchedulesURL}`,
      {
        method: "GET",
      }
    );
    yield put(loadSessionCntSuccess(res.data));
  } catch (error) {
    yield put(setError(error));
  }
}

function* getBookedSchedule(action) {
  try {
    const res = yield call(
      request,
      `${apiConfigs.userSchedulesURL}?start=${action.start}&end=${action.end}`,
      {
        method: "GET",
      }
    );
    yield put(loadBookedScheduleSuccess(res.data));
  } catch (error) {
    yield put(setError(error));
  }
}

function* bookGroupSchedule(action) {
  try {
    yield call(request, `${apiConfigs.userSchedulesURL}`, {
      method: "POST",
      data: JSON.stringify({ scheduleId: action.id }),
    });
    yield put(bookGroupScheduleSuccess());
  } catch (error) {
    yield put(setError(error));
  }
}

function* cancelBookedSchedule(action) {
  try {
    yield call(request, `${apiConfigs.userSchedulesURL}`, {
      method: "DELETE",
      data: JSON.stringify({ scheduleId: action.scheduleId }),
    });
    yield put(cancelBookedScheduleSuccess());
  } catch (error) {
    yield put(setError(error));
  }
}

function* attendingSchedule(action) {
  try {
    yield call(request, `${apiConfigs.userSchedulesURL}/attending`, {
      method: "POST",
      data: JSON.stringify({ scheduleId: action.scheduleId }),
    });
    yield put(updateAttendingSuccess());
  } catch (error) {
    yield put(setError(error));
  }
}

function* loadMemberTotalSession(action) {
  try {
    const res = yield call(request, `${apiConfigs.userURL}/totalCnt/v2`, {
      method: "GET",
    });
    yield put(loadMemberTotalSessionCntSuccess(res.data));
  } catch (error) {
    yield put(setError(error));
  }
}

function* loadWorkoutPostList(action) {
  try {
    const res = yield call(request, `${apiConfigs.workoutURL}/posts`, {
      method: "GET",
    });
    yield put(loadPostListSuccess(res.data));
  } catch (error) {
    yield put(setError(error));
  }
}

function* sagas() {
  yield takeLatest(GET_AUTH, getAuth);
  yield takeLatest(LOAD_GROUP_SCHEDULES, getGroupScheduleList);
  yield takeLatest(LOAD_MEBMER_INFO, getMemberInfo);
  yield takeLatest(LOAD_SESSION_CNT, getMemberSessionCnt);
  yield takeLatest(BOOK_GROUP_SCHEDULE, bookGroupSchedule);
  yield takeLatest(LOAD_BOOKED_SCHEDULE, getBookedSchedule);
  yield takeLatest(CANCEL_BOOKED_SCHEDULE, cancelBookedSchedule);
  yield takeLatest(UPDATE_ATTENDING, attendingSchedule);
  yield takeLatest(LOAD_MEMBER_TOTAL_SESSION_CNT, loadMemberTotalSession);
  yield takeLatest(LOAD_POST_LIST, loadWorkoutPostList);
}

export default sagas;
