import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

import { serviceConfigs } from '../../configs/Configs';

function Menu() {
  return (
    <aside>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="/">{serviceConfigs.serviceName}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/book">수업 예약</Nav.Link>
            <Nav.Link href="/booked">예약된 수업</Nav.Link>
            <Nav.Link href="/sessions">완료된 수업</Nav.Link>
            <Nav.Link href="/info">내 정보</Nav.Link>
            <Nav.Link href="/logout">로그아웃</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </aside>
  );
}

export default Menu;
