/*
 * src/store/modules/member.js
 * 회원 정보 관련 redux type, action, reducer
 */
import { fromJS } from 'immutable';

export const DEFAULT_ACTION = 'member/DEFAULT_ACTION';
export const LOAD_MEBMER_INFO = 'member/LOAD_MEBMER_INFO';
export const LOADED_MEMBER_INFO = 'member/LOADED_MEMBER_INFO';
export const LOAD_SESSION_CNT = 'member/LOAD_SESSION_CNT';
export const LOADED_SESSION_CNT = 'member/LOADED_SESSION_CNT';
export const LOAD_MEMBER_TOTAL_SESSION_CNT = 'member/LOAD_MEMBER_TOTAL_SESSION_CNT';
export const LOADED_MEMBER_TOTAL_SESSION_CNT = 'member/LOADED_MEMBER_TOTAL_SESSION_CNT';

export const defaultAction = () => {
  return {
    type: DEFAULT_ACTION,
  };
}

export const loadMemberInfo = () => {
  return {
    type: LOAD_MEBMER_INFO,
  };
}
export const loadMemberInfoSuccess = (res) => {
  return {
    type: LOADED_MEMBER_INFO,
    member: res,
  }
}


export const loadSessionCnt = () => {
  return {
    type: LOAD_SESSION_CNT,
  };
}
export const loadSessionCntSuccess = (res) => {
  return {
    type: LOADED_SESSION_CNT,
    seesionCnt: res,
  };
}

export const loadMemberTotalSessionCnt = () => {
  return {
    type: LOAD_MEMBER_TOTAL_SESSION_CNT,
  };
}
export const loadMemberTotalSessionCntSuccess = (res) => {
  return {
    type: LOADED_MEMBER_TOTAL_SESSION_CNT,
    totalCnt: res,
  };
}

const initialState = fromJS({
  loading: false,
  memberData: false,
  sessionData: fromJS({
    sessionType: false,
    cnt: false,
    totalCnt: false,
  }),
});

export default function memberReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case LOAD_MEBMER_INFO:
      return state
        .set('loading', false)
        .set('memberData', false);
    case LOADED_MEMBER_INFO:
      return state
        .set('loading', true)
        .set('memberData', action.member);
    case LOAD_SESSION_CNT:
      return state
        .set('loading', false)
        .setIn(['sessionData', 'cnt'], false);
    case LOADED_SESSION_CNT:
      return state
        .set('loading', true)
        .setIn(['sessionData', 'cnt'], action.seesionCnt);
    case LOAD_MEMBER_TOTAL_SESSION_CNT:
      return state
        .setIn(['sessionData', 'totalCnt'], false);
    case LOADED_MEMBER_TOTAL_SESSION_CNT:
      return state
        .setIn(['sessionData', 'totalCnt'], action.totalCnt);
    default:
      return state;
  }
}