import axios from 'axios';

// const parseJSON = (response) => {
//     return response.json();
// }

const checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }

    const error = new Error(response.statusText);
    throw error;
}

export default async (url, options) => {
    if (localStorage.getItem('access-token')) {
        axios.defaults.headers.common['access-token'] = `${localStorage.getItem('access-token')}`;
    }

    axios.defaults.headers.post['Accept'] = 'application/json';
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.delete['Accept'] = 'application/json';
    axios.defaults.headers.delete['Content-Type'] = 'application/json';
    let response;
    try {
        response = await axios(url, options);
        return checkStatus(response);
    } catch (error) {
        let err = error.response.data;
        throw { 
            status: err.status,
            code: err.code || '',
            message: err.message
        };
    }
}