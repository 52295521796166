import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

import setAlert from '../../lib/utils/alert';

class AlertMessage extends Component {
  render() {
    const message = setAlert(this.props.error);
    window.alert(`오류 발생: ${message}`);
    return (
      <Alert variant="danger" className="AlertMessage">
        {message}
      </Alert>
    );
  }
}

export default AlertMessage;
