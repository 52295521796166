import React, { Component } from 'react';
import {
  Accordion,
  Card
} from 'react-bootstrap';
import { FaChevronDown, FaChevronLeft, FaList } from 'react-icons/fa';

import SessionCard from './SessionCard';

class SessionListSecond extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }
  render() {
    let content = null;
    const iconComponent = this.state.open
    ? <FaChevronDown />
    : <FaChevronLeft />;
    
    return (
      <div className="SessionListSecond">
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={this.props.index}
              onClick={() => this.setState({ open: !this.state.open })}
            >
              <ul className="SessionListSecond_list">
                <li>
                  <span>
                    <FaList />
                  </span>
                  <span>{this.props.title}</span>
                </li>
                <li className="text_right">
                  {iconComponent}
                </li>
              </ul>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={this.props.index}>
              <Card.Body>
                <div className="card_list">
                  {this.props.schedules.map((s, i) => {
                    return <SessionCard 
                      key={i} 
                      index={i} 
                      schedule={s} 
                      bookedScheduleList={this.props.bookedScheduleList}
                      bookGroupSchedule={this.props.bookGroupSchedule}
                      expiredDate={this.props.expiredDate}
                      canBookCnt={this.props.canBookCnt}
                    />
                  })}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    );
  }
}

export default SessionListSecond;