import React from 'react';
import { Jumbotron } from 'react-bootstrap';

function NotificationPanel(props) {
  return (
    <Jumbotron>
      <h3>{props.title}</h3>
      {props.children}
    </Jumbotron>
  );
}

export default NotificationPanel;