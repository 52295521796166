import React from "react";
import moment from "moment";
import { Badge, Button } from "react-bootstrap";
import PTIcon from "../../lib/image/PT.png";
import GroupIcon from "../../lib/image/Group.png";

import { serviceConfigs } from "../../configs/Configs";
// const serviceConfigs = window.ENV.serviceConfigs;

function BookedSchedule(props) {
  const branchId = localStorage.getItem("branchId");
  const type = serviceConfigs.classType[props.s.type - 1];
  const date = `${moment(props.s.start).month() + 1}월 ${moment(
    props.s.start
  ).date()}일(${serviceConfigs.days[moment(props.s.start).day()]})`;
  const time = `${moment(props.s.start).format("HH:mm")}`;
  const badgeStyle = props.memberSchedule.status === false ? "info" : "warning";
  const bookStatus = props.memberSchedule.status === false ? "예약" : "대기";
  const scheduleName = props.s.name;
  const scheduleType = serviceConfigs.classType[props.s.type - 1];
  const teacherName = props.s.teacher ? props.s.teacher.name : "강사 미정";
  const roomName = props.s.room ? props.s.room.name : "룸 미정";
  let disableCancle = true;
  let btnMsg = "취소 가능 시간이 아닙니다.";

  if (
    serviceConfigs.ptClassType.indexOf(
      serviceConfigs.classType[props.s.type - 1]
    ) >= 0
  ) {
    disableCancle = true;
    btnMsg = "예약취소 불가";
  } else if (
    moment().isSameOrAfter(
      moment(props.s.start).subtract(
        serviceConfigs.groupBookingCancleLimit[branchId - 1],
        "hours"
      )
    )
  ) {
    disableCancle = true;
    btnMsg = "예약취소 시간초과";
  } else if (bookStatus === "예약") {
    disableCancle = false;
    btnMsg = "예약 취소";
  } else {
    disableCancle = false;
    btnMsg = "대기 취소";
  }

  const handleCancelSchedule = () => {
    let text;
    if (bookStatus === "예약") {
      text =
        `${date} / ${time} \n` +
        `예약을 취소하시겠습니까?\n` +
        `재예약이 불가할 수 있습니다.`;
    } else {
      text = `${date} / ${time} \n` + `대기를 취소하시겠습니까?\n`;
    }

    if (window.confirm(text)) {
      props.cancelBookedSchedule(props.s.id);
    }
  };

  return (
    <div className="BookedSchedule">
      <h6>
        <img
          src={
            serviceConfigs.groupClassType.indexOf(type) >= 0
              ? GroupIcon
              : PTIcon
          }
          className="schedule_icon"
          alt="logo"
        />
        &nbsp;&nbsp;
        {date}
      </h6>
      <h4>
        {`[${scheduleType}] ${time}`}{" "}
        <Badge variant={badgeStyle}>{bookStatus}</Badge>
      </h4>
      <Tag tag={scheduleName} />
      <Tag tag={teacherName} />
      <Tag tag={roomName} />
      <div className="cancle">
        <Button
          variant="outline-danger"
          block
          disabled={disableCancle}
          onClick={handleCancelSchedule}
        >{`${btnMsg}`}</Button>
      </div>
    </div>
  );
}

export default BookedSchedule;

function Tag(props) {
  return <div className="Tag">{props.tag}</div>;
}
