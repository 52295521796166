import React, { Component } from "react";
import { connect } from "react-redux";
import {
  InputGroup,
  FormControl,
  ButtonToolbar,
  Button,
} from "react-bootstrap";
import { FaUserAlt, FaKey } from "react-icons/fa";
import "../lib/css/LoginPage.css";
import { serviceConfigs } from "../configs/Configs";

//import components
import LogoBox from "../components/LogoBox";
import AlertMessage from "../components/AlertMessage";

//import redux
import * as loginActions from "../store/modules/login";
import * as errorActions from "../store/modules/error";

export class LoginPage extends Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.props.setLogin();
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = {
      id: "",
      pwd: "",
    };
  }

  // handle actions
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleLogin(e) {
    e.preventDefault();
    this.props.getAuth({
      identity: this.state.id,
      password: this.state.pwd,
    });
  }
  handleKeyPress(e) {
    if (e.keyCode === 13) {
      this.handleLogin(e);
    }
  }

  render() {
    let alert = null,
      manual = null;
    if (localStorage.getItem("access-token")) {
      if (this.props.location.state) {
        window.location.href = this.props.location.state.from.pathname;
      } else {
        window.location.href = "/";
      }
    }
    if (this.props.error) {
      alert = <AlertMessage error={this.props.errDetail} />;
    }
    if (serviceConfigs.manualURL != "") {
      manual = (
        <p>
          <a href={serviceConfigs.manualURL} target="_blank">
            사용자 매뉴얼
          </a>
        </p>
      );
    }
    return (
      <div className="main">
        {alert}
        <LogoBox />
        {manual}
        <div className="LoginBox">
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">
                <FaUserAlt />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="아이디"
              aria-label="아이디"
              aria-describedby="basic-addon1"
              name="id"
              onChange={this.handleChange}
              onKeyDown={this.handleKeyPress}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">
                <FaKey />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="패스워드"
              aria-label="패스워드"
              aria-describedby="basic-addon1"
              type="password"
              name="pwd"
              onChange={this.handleChange}
              onKeyDown={this.handleKeyPress}
            />
          </InputGroup>
          <ButtonToolbar>
            <Button variant="outline-dark" block onClick={this.handleLogin}>
              로그인
            </Button>
          </ButtonToolbar>
        </div>
        {/* <Forgot /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.login.get("loading"),
  error: state.error.get("error"),
  errDetail: state.error.get("errDetail").toJS(),
});

const mapDispatchToProps = (dispatch) => ({
  getAuth: (user) => dispatch(loginActions.getAuth(user)),
  setLogin: () => dispatch(loginActions.setLogin()),
  resetError: () => dispatch(errorActions.resetError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

// const Forgot = () => {
//   return (
//       <div className="Login-link">
//           <Link to="/resetPwd">비밀번호를 잊으셨나요?</Link>
//       </div>
//   )
// }

// 190817 회원페이지 통합 시 사용
// const Register = () => {
//   return (
//       <div className="Login-link">
//           <Link to ="/register">계정이 없으신가요?</Link>
//       </div>
//   );
// }
