/*
 * Comine Reducers
 * making multiple reduers in store directory and comine them together in here
 * store 디렉터리 내 생성한 여러개의 리듀서를 하나의 리듀서로 통합
 */
import { combineReducers } from "redux";

// modules
import error from "./error";
import login from "./login";
import book from "./book";
import booked from "./booked";
import member from "./member";
import sessions from "./sessions";
import workout from "./workout";

export default combineReducers({
  error,
  login,
  book,
  booked,
  member,
  sessions,
  workout,
});
