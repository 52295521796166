import React, { Component } from 'react';
import { InputGroup, FormControl, ButtonToolbar, Button } from 'react-bootstrap';
import { FaUserAlt, FaMobile } from 'react-icons/fa';
import '../lib/css/LoginPage.css';

//import components
import LogoBox from '../components/LogoBox';

export class ResetPage extends Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <div className="main">
        <LogoBox />
        <div className="LoginBox">
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1"><FaUserAlt /></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="이름"
              aria-label="이름"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1"><FaMobile /></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="휴대전화 번호"
              aria-label="휴대전화 번호"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
          <ButtonToolbar>
            <Button variant="outline-dark" block>계정 초기화</Button>
            <Button variant="outline-danger" block>취소</Button>
          </ButtonToolbar>
        </div>
      </div>
    );
  }
}

export default ResetPage;