import React from "react";
import moment from "moment";
import { Card, Button } from "react-bootstrap";

import { serviceConfigs } from "../../configs/Configs";
// const serviceConfigs = window.ENV.serviceConfigs;

function BoardCard(props) {
  let sumnail,
    videoSrc,
    title,
    content = "";
  const branchId = localStorage.getItem("branchId");

  title = props.title;
  content = props.content.split("\\n").map((s, i) => {
    return (
      <span key={i}>
        {s}
        <br />
      </span>
    );
  });

  props.attachments.map((a, i) => {
    switch (a.type) {
      case 1:
        sumnail = a.url;
        break;
      case 2:
        videoSrc = a.url;
        break;
      default:
        break;
    }
  });

  const onClickBtn = () => {
    props.handleClickBtn(props.index);
  };

  return (
    <div className="BoardCard">
      <Card style={{ width: "18rem" }}>
        <Card.Img variant="top" src={`${sumnail}`} />
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{content}</Card.Text>
          <Button variant="primary" onClick={onClickBtn}>
            운동 영상 보기
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default BoardCard;
