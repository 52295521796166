/*
 * src/store/modules/sessions.js
 * BookedPage Container의 redux type, action, reducer
 */
import { fromJS } from 'immutable';

export const DEFAULT_ACTION = 'sessions/DEFAULT_ACTION';
export const SET_TARGET_DATE = 'sessions/SET_TARGET_DATE';
export const UPDATE_ATTENDING = 'sessions/UPDATE_ATTENDING';
export const UPDATED_ATTENDING = 'sessions/UPDATED_ATTENDING';

export const defaultAction = () => {
  return {
    type: DEFAULT_ACTION,
  };
}

export const setTartgetDate = (start, end) => {
  return {
    type: SET_TARGET_DATE,
    start: start,
    end: end
  }
}

export const updateAttending = (scheduleId) => {
  return {
    type: UPDATE_ATTENDING,
    scheduleId: scheduleId
  }
}
export const updateAttendingSuccess = () => {
  return {
    type: UPDATED_ATTENDING
  }
}

const initialState = fromJS({
  loading: false,
  updateSuccess: false,
  sessionData: fromJS({
    start: false,
    end: false,
  }),
});

export default function sessionsReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case SET_TARGET_DATE:
      return state
        .set('updateSuccess', false)
        .setIn(['sessionData', 'start'], action.start)
        .setIn(['sessionData', 'end'], action.end);
    case UPDATE_ATTENDING:
      return state
        .set('updateSuccess', false);
    case UPDATED_ATTENDING:
      return state
        .set('updateSuccess', true);
    default:
      return state;
  }
}