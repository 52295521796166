import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import moment from "moment";

// Css import
import "./lib/css/App.css";
import "./lib/css/Components.css";

// Container import
import MainPage from "./containers/MainPage";
import LoginPage from "./containers/LoginPage";
import ResetPage from "./containers/ResetPage";
import BookPage from "./containers/BookPage";
import BookedPage from "./containers/BookedPage";
import SessionsPage from "./containers/SessionsPage";
import InfoPage from "./containers/InfoPage";
import WorkoutPage from "./containers/WorkoutPage";
// Components import
import Menu from "./components/Menu";
import Footer from "./components/Footer";

function App({ store }) {
  return (
    <Provider store={store}>
      <Router>
        <div className="containerMain">
          <div className="c1">
            <Menu />
          </div>
          <div className="c4">
            <div className="c2">
              <PrivateRoute path="/" exact component={MainPage} />
              <Route path="/login" exact component={LoginPage} />
              <Route path="/resetPwd" exact component={ResetPage} />
              <PrivateRoute path="/book" component={BookPage} />
              <PrivateRoute path="/booked" component={BookedPage} />
              <PrivateRoute path="/sessions" component={SessionsPage} />
              <PrivateRoute path="/info" component={InfoPage} />
              <PrivateRoute path="/workout" component={WorkoutPage} />
              <Route path="/logout" exact component={Logout} />
            </div>
            <div className="c3">
              <Footer />
            </div>
          </div>
        </div>
      </Router>
    </Provider>
  );
}

export default App;

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      checkTokenExpired();
      return localStorage.getItem("access-token") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

const Logout = () => {
  localStorage.clear();
  window.location.href = "/login";
};

const checkTokenExpired = () => {
  if (localStorage.getItem("access-token")) {
    let decodedToken = jwt_decode(localStorage.getItem("access-token"));
    if (moment().isAfter(decodedToken.expiredTime)) {
      window.location.href = "/logout";
    }
  }
};
