import React from "react";
import { Accordion } from "react-bootstrap";

import SessionListCard from "./SessionListCard";
import { bucketSort } from "./SessionSort";

function SessionListFirst(props) {
  if (!props.scheduleList || Object.keys(props.scheduleList).length <= 0) {
    return null;
  }
  const sortedSchedules = bucketSort(props.scheduleList, props.filter);
  let buketKeys = Object.keys(sortedSchedules);
  if (props.filter !== "date") {
    buketKeys.sort();
  }
  return (
    <div className="SessionListFirst">
      <Accordion defaultActiveKey="0">
        {buketKeys.map((t, i) => {
          return (
            <SessionListCard
              key={i}
              index={i + 1}
              title={t}
              filter={props.filter}
              schedules={sortedSchedules[t]}
              bookedScheduleList={props.bookedScheduleList}
              bookGroupSchedule={props.bookGroupSchedule}
              expiredDate={props.expiredDate}
              canBookCnt={props.canBookCnt}
            />
          );
        })}
      </Accordion>
    </div>
  );
}

export default SessionListFirst;
