import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import modules from './modules';
import sagas from './sagas';

const configure = () => {
    const sagaMiddleware = createSagaMiddleware();

    const middlware = [sagaMiddleware];
    const store = createStore(
        modules,
        compose(
            applyMiddleware(...middlware),
        )
    );

    sagaMiddleware.run(sagas);
    
    return store;
}

export default configure();