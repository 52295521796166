import React from 'react';
import {
  ButtonGroup,
  ToggleButton
} from 'react-bootstrap';

function DateButton(props) {
  const onChangeDate = (e) => {
    props.changeDate(e.target.value);
  }
  return (
    <div className="inputGroup d-flex flex-column">
      <ButtonGroup toggle className="mt-3">
        <ToggleButton type="radio" name="radio" value="1" onChange={onChangeDate}>
          이전 달
        </ToggleButton>
        <ToggleButton type="radio" name="radio" value="2" onChange={onChangeDate}>
          오늘
        </ToggleButton>
        <ToggleButton type="radio" name="radio" value="3" onChange={onChangeDate}>
          다음 달
        </ToggleButton>
      </ButtonGroup>
    </div>
  );
}

export default DateButton;