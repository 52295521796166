/*
 * src/store/modules/workout.js
 * WorkoutPage Container의 redux type, action, reducer
 */
import { fromJS } from "immutable";

export const DEFAULT_ACTION = "workout/DEFAULT_ACTION";
export const LOAD_POST_LIST = "workout/LOAD_POST_LIST";
export const LOAD_POST_LIST_SUCCESS = "workout/LOAD_POST_LIST_SUCCESS";

export const defaultAction = () => {
  return {
    type: DEFAULT_ACTION,
  };
};

export const loadPostList = () => {
  return {
    type: LOAD_POST_LIST,
  };
};

export const loadPostListSuccess = (res) => {
  return {
    type: LOAD_POST_LIST_SUCCESS,
    list: res,
  };
};

const initialState = fromJS({
  loading: false,
  postData: fromJS({
    list: false,
  }),
});

export default function workoutReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case LOAD_POST_LIST:
      return state;
    case LOAD_POST_LIST_SUCCESS:
      return state
        .set("loading", true)
        .setIn(["postData", "list"], action.list);
    default:
      return state;
  }
}
