import React from 'react';
import moment from 'moment';
import 'moment/locale/ko';
import { Card, Button } from 'react-bootstrap';

import { serviceConfigs } from '../../configs/Configs';
import { isBooked } from './SessionSort';

moment.locale('ko');
const days = ['일', '월', '화', '수', '목', '금', '토'];
const branchId = localStorage.getItem('branchId');

function canBook(schedule, bookedScheduleList) {
  if (!schedule || !bookedScheduleList) {
    return {};
  }
  const bookedMember = schedule.member.length;
  const canBookNumber = schedule.seats;
  // const usingWaiting = serviceConfigs.usingWaiting;
  const usingWaiting = serviceConfigs.usingWaiting[branchId - 1];
  const canBookTime = moment(schedule.start).subtract(serviceConfigs.groupBookingAvaibleLimit[branchId - 1], 'minutes');
  const alreadyBooked = isBooked(schedule.id, bookedScheduleList);

  if (alreadyBooked) {
    return {
      id: schedule.id,
      canBook: false,
      canWaiting: false,
      class: 'canNotBook',
      title: `예약/대기 완료`,
    };
  }

  if (moment().isAfter(canBookTime)) {
    return {
      id: schedule.id,
      canBook: false,
      canWaiting: false,
      class: 'canNotBook',
      title: `예약 불가 시간`,
    };
  }

  if (bookedMember < canBookNumber) {
    return {
      id: schedule.id,
      canBook: true,
      canWaiting: false,
      class: 'canBook',
      title: `예약 가능(${canBookNumber - bookedMember}명)`,
      buttonTitle: '예약하기',
    };
  } else if (usingWaiting && bookedMember < canBookNumber * 2) {
    return {
      id: schedule.id,
      canBook: false,
      canWaiting: true,
      class: 'canWaiting',
      title: `대기 가능(${canBookNumber * 2 - bookedMember}명)`,
      buttonTitle: '대기하기',
    };
  } else if (usingWaiting) {
    return {
      id: schedule.id,
      canBook: false,
      canWaiting: false,
      class: 'canNotBook',
      title: `예약/대기 불가`,
    };
  } else {
    return {
      id: schedule.id,
      canBook: false,
      canWaiting: false,
      class: 'canNotBook',
      title: `예약 불가`,
    };
  }
}

function confirmSchedule(schedule) {
  let text;
  if (schedule.canBookCnt <= 0) {
    text = `예약 가능한 세션이 없습니다.`;
    window.alert(text);
    return 0;
  }
  if (moment(schedule.expiredDate).add(1, 'd').isSameOrBefore(schedule.info.start)) {
    text = `세션 만료일 이후 수업은 예약할 수 없습니다.`;
    window.alert(text);
    return 0;
  }
  if (schedule.bookInfo.canBook) {
    text = `${moment(schedule.info.start).format('YYYY-MM-DD(ddd) HH:mm')}\n` + `예약 하시겠습니까?`;
  } else if (schedule.bookInfo.canWaiting) {
    text =
      `${moment(schedule.info.start).format('YYYY-MM-DD(ddd) HH:mm')}\n` +
      `대기 하시겠습니까?\n\n` +
      `대기 시엔 순번에 따라 이전 예약자 취소 후 예약 상태로 전환됩니다.\n` +
      `대기에서 예약으로 전환 시 카카오톡 메시지 안내가 수행되오나,\n` +
      `정확한 확인을 위해 MyPage에서 당일 직접 확인 부탁 드립니다.`;
  } else {
    text = `예약 가능한 수업이 없습니다.`;
    window.alert(text);
    return 0;
  }

  if (window.confirm(text)) {
    schedule.bookGroupSchedule(schedule.info.id);
  }
}

function SessionCard(props) {
  const bookInfo = canBook(props.schedule, props.bookedScheduleList);
  const bookBtn =
    bookInfo.canBook || bookInfo.canWaiting ? (
      <Button
        variant="outline-primary"
        onClick={() =>
          confirmSchedule({
            bookInfo: bookInfo,
            info: props.schedule,
            bookGroupSchedule: props.bookGroupSchedule,
            expiredDate: props.expiredDate,
            canBookCnt: props.canBookCnt,
          })
        }
      >
        {bookInfo.buttonTitle}
      </Button>
    ) : null;
  return (
    <div className="SessionCard">
      <Card>
        <Card.Header style={{ backgroundColor: props.schedule.color }}>{`${moment(props.schedule.start).format('MM.DD')}(${days[moment(props.schedule.start).day()]})`}</Card.Header>
        <Card.Body>
          <Card.Title className={bookInfo.class}>{bookInfo.title}</Card.Title>
          <Card.Text>
            {`${props.schedule.name}`}
            <br />
            {`${moment(props.schedule.start).format('HH시 mm분')}`}
            <br />
            {`${props.schedule.teacher ? props.schedule.teacher.name + ' 강사' : '강사 미정'}`}
            <br />
            {`${props.schedule.room ? props.schedule.room.name : '룸 미정'}`}
            <br />
            {`${serviceConfigs.classType[props.schedule.type - 1]} 수업`}
          </Card.Text>
          {bookBtn}
        </Card.Body>
      </Card>
    </div>
  );
}

export default SessionCard;
