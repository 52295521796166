export default (errDetail) => {
  switch (errDetail.code) {
    case 20000:
      return '아이디 및 패스워드를 확인해 주세요';
    case 30001:
      return '요청하신 수업이 존재하지 않습니다.';
    case 30002:
      return '이미 수업 출석 처리가 되었습니다.';
    case 30003:
      return '요청하신 타입의 수업을 위해 사용 가능한 세션이 없습니다.';
    case 30004:
      return '만료일이 경과하여, 예약할 수 없습니다.';
    case 30005:
      return '이미 예약된 수업입니다.';
    case 30006:
      return '예약할 수 없는 수업 타입을 선택하셨습니다. 해당 수업의 세션이 없거나, 센터에서 예약을 허용하지 않는 수업 타입니다.';
    case 30007:
      return '예약 가능한 인원이 없습니다.';
    case 30008:
      return '예약 가능한 시간이 아닙니다. 예약 가능한 수업은 수업 시작 시간을 기준으로 시작됩니다.';
    case 30009:
      return '예약 취소 가능한 시간이 아닙니다.';
    case 30014:
      return '소지한 세션 만큼 수업을 예약 하여, 추가 예약이 불가합니다. 기존 예약을 취소하거나 세션을 추가하여야 합니다.';
    case 30015:
      return '이미 예약한 수업과 동일한 시간의 수업은 예약 및 대기할 수 없습니다.';
    case 40001:
      return '인증에 실패하였습니다.';
    case 40002:
      return '존재하지 않는 데이터입니다.';
    case 40003:
      return '이미 존재하는 데이터 입니다.';
    case 40004:
      return '필요한 권한이 없습니다.';
    case 40101:
      return '아이디 및 패스워드 확인이 필요합니다.';
    default:
      return '오류가 발생했습니다.';
  }
};
