/*
 * src/store/modules/book.js
 * BookPage Container의 redux type, action, reducer
 */
import { fromJS } from 'immutable';

export const DEFAULT_ACTION = 'book/DEFAULT_ACTION';
export const CHANGE_FILTER = 'book/CHANGE_FILTER';
export const SET_START_DATE = 'book/SET_START_DATE';
export const SET_END_DATE = 'book/SET_END_DATE';
export const LOAD_GROUP_SCHEDULES = 'book/LOAD_GROUP_SCHEDULES';
export const LOADED_GROUP_SCHEDULES = 'book/LOADED_GROUP_SCHEDULES';
export const BOOK_GROUP_SCHEDULE = 'book/BOOK_GROUP_SCHEDULE';
export const BOOKED_GROUP_SHCEDULE = 'book/BOOKED_GROUP_SCHEDULE';

export const defaultAction = () => {
  return {
    type: DEFAULT_ACTION,
  };
}

export const changeFilter = (filter) => {
  return {
    type: CHANGE_FILTER,
    filter: filter
  };
}

export const setStartDate = (date) => {
  return {
    type: SET_START_DATE,
    start: date
  };
}

export const setEndDate = (date) => {
  return {
    type: SET_END_DATE,
    end: date
  };
}

export const loadGroupSchedules = (start, end) => {
  return {
    type: LOAD_GROUP_SCHEDULES,
    start: start,
    end: end,
  };
}
export const loadGroupSchedulesSuccess = (res) => {
  return {
    type: LOADED_GROUP_SCHEDULES,
    groupScheduleList: res,
  };
}

export const bookGroupSchedule = (id) => {
  return {
    type: BOOK_GROUP_SCHEDULE,
    id: id
  };
}
export const bookGroupScheduleSuccess = () => {
  return {
    type: BOOKED_GROUP_SHCEDULE,
  };
}

const initialState = fromJS({
  loading: false,
  bookSuccess: false,
  filter: false,
  scheduleData: fromJS({
    groupShceuleList: false,
    schedule: false,
    scheduleId: false,
    scheduleIdList: false,
    status: false,
    start: false,
    end: false,
  }),
});

export default function bookReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case CHANGE_FILTER:
      return state
        .set('filter', action.filter);
    case SET_START_DATE:
      return state
        .setIn(['scheduleData', 'start'], action.start);
    case SET_END_DATE:
      return state
        .setIn(['scheduleData', 'end'], action.end);
    case LOAD_GROUP_SCHEDULES:
      return state
        .set('loading', false)
        .set('bookSuccess', false)
        .setIn(['scheduleData', 'start'], action.start? action.start:state.getIn(['scheduleData', 'start']))
        .setIn(['scheduleData', 'end'], action.end? action.end:state.getIn(['scheduleData', 'end']))
        .setIn(['scheduleData', 'groupScheduleList'], false);
    case LOADED_GROUP_SCHEDULES:
      return state
        .set('loading', true)
        .setIn(['scheduleData', 'groupScheduleList'], action.groupScheduleList);
    case BOOK_GROUP_SCHEDULE:
      return state
        .set('loading', false)
        .set('bookSuccess', false)
        .setIn(['scheduleData', 'scheduleId'], action.id);
    case BOOKED_GROUP_SHCEDULE:
      return state
        .set('loading', true)
        .set('bookSuccess', true);
    default:
      return state;
  }
}