import React from 'react';
import centerLogo from '../../lib/image/center_logo.png';

import { serviceConfigs } from '../../configs/Configs';

function LogoBox() {
  const branchId = localStorage.getItem('branchId');
  let branch = '';
  if ( branchId ) {
    branch = serviceConfigs.branch[branchId - 1];
  }
  return (
    <div className="LogoBox">
      <img src={centerLogo} className="centerLogo" alt="CenterLogo" />
      <h2>{serviceConfigs.serviceName}</h2>
      <h3>{branch}</h3>
      <h4>수업 예약 서비스</h4>
    </div>
  );
}

export default LogoBox;