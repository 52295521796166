import moment from 'moment';

import { serviceConfigs } from '../../configs/Configs';

export function setBucket(s, filter) {
  switch (filter) {
    case 'time':
      return moment(s.start).format('HH:mm');
    case 'date':
      return serviceConfigs.days[moment(s.start).day()];
    case 'teacher':
      if ( !s.teacher ) {
        return '강사 미정';
      } else {
        return s.teacher.name;
      }
    case 'room':
      if ( !s.room ) {
        return '룸 미정';
      } else {
        return s.room.name;
      }
    case 'name':
      return s.name;
    case 'type':
      return serviceConfigs.classType[s.type - 1];
    case 'available':
      if (s.seats - s.member.length > 0) return '1. 예약 가능';
      else if (serviceConfigs.usingWaiting && (s.seats * 2) - s.member.length > 0) return '2. 대기 가능';
      else return '예약 불가';
    default:
      return moment(s.start).format('HH:mm');
  }
}

// 버킷 분류 수행. 1차 기준으로 대략 분류
// 분류 기준 별로 할 것인가, 하나의 함수에서 다 처리할 것인가?
// time, date, teacher, room, name
export function bucketSort(scheduleList, filter) {
  if ( !scheduleList || scheduleList.length <= 0 ) return null;

  let list = scheduleList;
  let sorted = {};

  list.map((s, i) => {
    const bucket = setBucket(s, filter);
    if ( !sorted.hasOwnProperty(bucket) ) {
      sorted[bucket] = [];
    }
    sorted[bucket].push(s);
    return s;
  });
  return sorted;
}

export function isBooked(id, bookedScheduleList) {
  if ( !bookedScheduleList || bookedScheduleList.length <= 0 ) return null;

  const bookedScheduleIdList = bookedScheduleList.map((bs, i) => bs.id);
  return (bookedScheduleIdList.indexOf(id) >= 0)? true:false;
}