import React from 'react';
import {
  Card
} from 'react-bootstrap';

function NumberCard(props) {
  return (
    <div className="NumberCard">
      <Card>
        <Card.Header className="text_center">
          {props.title}
        </Card.Header>
        <Card.Body>
          <Card.Title className="text_center">
            {props.count? props.count:0}
          </Card.Title>
        </Card.Body>
      </Card>
    </div>
  );
}

export default NumberCard;